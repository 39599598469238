import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons/faArrowCircleRight';
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons/faArrowCircleLeft';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';

export default {
  init() {
    // JavaScript to be fired on all pages
    config.searchPseudoElements = true;
    library.add(
      faSearch,
      faTwitterSquare,
      faFacebookSquare,
      faLinkedin,
      faYoutube,
      faInstagram,
      faArrowCircleRight,
      faArrowCircleLeft,
      faChevronDown,
      faPlusCircle,
      faMinusCircle
    );
    dom.watch();

    $('.accordion-title').on('click', function () {
      $(this).parent().find('.accordion-content').slideToggle();
      $(this).parent().toggleClass('open');
    });

    $('select').on('change', function () {
      $(this).addClass('changed');
    });

    $('.year').on('click', function () {
      const id = $(this).attr('id');
      $('.year').removeClass('active');
      $(this).addClass('active');
      $('.timeline-desc').hide();
      $('.' + id).fadeIn();
    });

    $('.modal-trigger').on('click', function (e) {
      e.preventDefault();
      $('.modal').hide();
      const modal = $(this).attr('data-modal');
      $('#modal-' + modal).fadeIn();
    });

    $('#abstract').on('click', function (e) {
      e.preventDefault();
      $('.modal').hide();
      $('#abstract-modal').addClass('open');
      $('#abstract-modal').fadeIn();
    });

    $('.close').on('click', function () {
      $('.modal').hide();
    });

    $('.banner-slider').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
    });

    // Navigation
    navDesktop();
    navMobile();

    // Desktop navigation
    function navDesktop() {
      function hideSubnav() {
        $('header').removeClass('expanded');
        $('.mega').removeClass('active');
      }
      function showSubnav(target) {
        target.addClass('active');
        $('header').addClass('expanded');
        $('.mega').not(target).removeClass('active');
      }
      $('.main-menu li:not(.has-buttons)').hover(function () {
        if ($(window).width() > 1024) {
          var target = $(this).attr('class').split(' ')[0] + '-nav';
          showSubnav($('#' + target));
        }
      });
      $('header').on('mouseleave', function () {
        if ($(window).width() > 1024) {
          hideSubnav();
        }
      });
      $('.logo,li.has-buttons,.utility-menu').on('mouseenter', function () {
        if ($(window).width() > 1024) {
          hideSubnav();
        }
      });
    }

    // Mobile navigation
    function navMobile() {
      function showSubnav(target) {
        $('#primary #menu').removeClass('active');
        target.addClass('active');
      }

      $('.mobile-open').on('click', function () {
        var $this = $(this);

        // Toggle menu
        if ($this.hasClass('close')) {
          $('body,html').removeClass('locked');
          $('header').removeClass('expanded');
          $('#primary #menu').removeClass('active');
          $('.mobile-close').removeClass('active');
          setTimeout(function () {
            $this.removeClass('close');
          }, 10);
        } else {
          $('body,html').addClass('locked');
          $('header').addClass('expanded');
          $('#primary #menu').addClass('active');
          $('.mega').removeClass('active');
          setTimeout(function () {
            $this.addClass('close');
          }, 10);
        }
      });

      // Top level -> Submenu
      $('#primary #menu li:not(.has-buttons) a').click(function () {
        if ($(window).width() <= 1024) {
          var target = $(this).parent().attr('class').split(' ')[0] + '-nav';
          showSubnav($('#' + target));
        console.log(target);
        return false;
        }
      });

      $('.mega .back').on('click', function () {
        $('.mega').removeClass('active');
        $('#primary #menu').addClass('active');
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
